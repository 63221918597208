import moment from 'moment/moment'
import {
  USER_ONE_TIME_PURCHASE,
  USER_PHASE_1,
  USER_PHASE_2,
  USER_PHASE_3
} from '../../constants/user/user-phase'

export function getSubscriptionPrice(product, frequency) {
  const subscriptionPrices = product.subscription_prices || []
  if (subscriptionPrices.length === 0) return null
  const subscriptionPrice = subscriptionPrices.find(price => price.period === frequency)
  return subscriptionPrice
}

export function checkProductInSubscription(subscriptions, productId) {
  if (subscriptions.length === 0) return false

  return !!subscriptions.find(subscription => {
    const itemIds = Object.keys(subscription.items || {})
    return Boolean(itemIds.find(itemId => itemId.includes(productId)))
  })
}

export function checkOnetimeProductInSubscription(subscriptions, productId) {
  if (subscriptions.length === 0) return false

  return !!subscriptions.find(subscription => {
    const itemIds = Object.keys(subscription.oneTimeItems || {})
    return Boolean(itemIds.find(itemId => itemId.includes(productId)))
  })
}

/**
 * this function return in which phase the user is, using the activeSubscription
 * the last active subscription determines the phase
 * @param activeSubscriptions
 * @returns user's phase
 */
export const getUserPhase = (activeSubscriptions = []) => {
  if (activeSubscriptions?.length === 0) {
    // one time subscriber
    return USER_ONE_TIME_PURCHASE
  } else {
    activeSubscriptions.sort((as1, as2) => (as1?.createdAt > as2.createdAt ? 1 : -1))
    const lastActiveSubscription = activeSubscriptions[0]
    const now = moment()
    const lastActiveSubscriptionDate = moment(lastActiveSubscription?.createdAt * 1000)
    const diff = now.diff(lastActiveSubscriptionDate, 'months')
    if (diff <= 6) {
      return USER_PHASE_1
    } else if (diff <= 12) {
      return USER_PHASE_2
    } else {
      return USER_PHASE_3
    }
  }
}

/**
 * this function return in which season the user is, using the months
 * or return last set if the months is greater than the latestSets
 * @param latestSets
 * @param months
 * @returns {string|string}
 */
export const getSetByPeriod = (latestSets, months) => {
  if (!latestSets || latestSets?.length === 0) {
    return null
  }
  const firstSet = latestSets[0]
  const nextMonths = moment(firstSet?.formulation_date).add(months, 'months')
  const set = latestSets.find(set => {
    const setFormulationDate = moment(set?.formulation_date)
    return setFormulationDate.isSameOrAfter(nextMonths)
  })

  return set || latestSets[latestSets.length - 1]
}
